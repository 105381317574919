/*
Copyright 2019-2024 New Vector Ltd.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_AuthPage {
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    background-color: $authpage-bg-color;
    height: 100vh;
    overflow: auto;
    /* elecord, screen border shadow */
    box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.5);
    /* elecord, background pattern */
    background-image: repeating-radial-gradient(
        circle at 50% 420px,
        rgba(255, 255, 255, 0.03),
        rgba(255, 255, 255, 0.03) 1px,
        transparent 2px,
        transparent 114px
    );
}

.mx_AuthPage_modal {
    display: flex;
    margin: 100px auto auto;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.33);
    background-color: $authpage-modal-bg-color;

    @media only screen and (max-height: 768px) {
        margin-top: 50px;
    }

    @media only screen and (max-width: 480px) {
        margin-top: 0;
    }
}

/* elecord, lava lamp effect */
.mx_AuthPage_modalBlurBackground {
    overflow: hidden;
    position: absolute;
    inset: 0px;
    border-radius: 7px;
}

@keyframes lavaLamp {
    0% {
        transform: translateX(-150px) rotate(0deg) scale(1);
        filter: hue-rotate(0deg) blur(36px);
        opacity: 1;
    }

    50% {
        transform: translateX(-150px) rotate(180deg) scale(1.2);
        filter: hue-rotate(20deg) blur(36px);
        opacity: 0.8;
    }

    100% {
        transform: translateX(-150px) rotate(360deg) scale(1);
        filter: hue-rotate(0deg) blur(36px);
        opacity: 1;
    }
}

.mx_AuthPage_modalBlur {
    inset: 0px;
    animation: 8s linear 0s infinite normal none running lavaLamp;
}

/* end lava lamp effect */
