/*
Copyright 2025 hazzuk.

SPDX-License-Identifier: AGPL-3.0-only
Please see LICENSE files in the repository root for full details.
*/

.mx_AppVersionButton {
    border-radius: 10px;
    background-color: #2c2f32bd;
    width: 75%;
    align-self: center;
    cursor: pointer;
}

.mx_AppVersionButton:hover {
    background-color: var(--cpd-color-gray-600);
    .mx_AppVersionButton_title {
		color: #62a9df;
	}
	.mx_AppVersionButton_number {
		color: var(--cpd-color-text-primary);
	}
}

.mx_AppVersionButton_title {
    text-align: center;
    color: #62a9df70;
    font-size: small;
    margin: 3px 0 0px 0px;
    font-weight: bold;
}

.mx_AppVersionButton_dev {
    color: #df946270 !important;
}

.mx_AppVersionButton_number {
    color: #727374;
    text-align: center;
    font-size: smaller;
    margin: 0 0 3px 0;
    overflow: hidden;
    text-wrap-mode: nowrap;
}
