/*
Copyright 2024 New Vector Ltd.
Copyright 2022 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

/* SCSS variables representing a range of standard lengths.
 *
 * Avoid using these in new code: we cannot adjust their values without causing massive confusion, so they are
 * effectively equivalent to using hardcoded values.
 *
 * In future, we plan to introduce variables named according to their purpose rather than their size. Additionally,
 * we want switch to custom CSS properties (https://github.com/vector-im/element-web/issues/21656), so we might have
 * `--spacing-standard` or something. For now, you might as well use hardcoded px values for lengths (except for font
 * sizes, for which see the `$font-<N>px` variables).
 */
$spacing-2: 2px;
$spacing-4: 4px;
$spacing-8: 8px;
$spacing-12: 12px;
$spacing-16: 16px;
$spacing-20: 20px;
$spacing-24: 24px;
$spacing-28: 28px;
$spacing-32: 32px;
$spacing-40: 40px;
$spacing-48: 48px;
$spacing-64: 64px;
