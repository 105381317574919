/*
Copyright 2024 New Vector Ltd.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_PresenceIconView {
    position: absolute;
    top: 24px;
    left: 24px;
    width: 12px;
    height: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--cpd-color-bg-canvas-default);
    border-radius: 100%;

    .mx_PresenceIconView_online {
        /* color: var(--cpd-color-icon-accent-primary); */
        color: var(--cpd-color-green-900);
    }

    .mx_PresenceIconView_offline,
    .mx_PresenceIconView_dnd {
        color: var(--cpd-color-icon-tertiary);
    }

    .mx_PresenceIconView_unavailable {
        color: var(--cpd-color-icon-quaternary);
    }
}
